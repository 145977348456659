import styled from 'styled-components';

const PageWrapper = styled.div`
  padding: 5.3rem 0;
  @media only screen and (max-width: 768px) {
    padding-top: 0;
  }
  .center-wrapper {
    background-color: var(--background);
    box-shadow: 0px 0.3rem 0.6rem rgba(0,0,0,0.16);
    color: var(--text);
    margin: 0 auto;
    max-width: 156.6rem;
    padding: 5rem;
  }
  .c-TeamSelector {
    @media only screen and (max-width: 768px) {
      margin-top: 3rem;
    }
  }
  .page-controls {
    margin: 0 auto;
    max-width: 149rem;
    width: 100%;
  }
  .current-selector {
    display: flex;
    div {
      background-color: #F1F1F1; 
      color: #707070;
      cursor: pointer;
      font-weight: bold;
      padding: 1rem 1.5rem;
      text-transform: uppercase;
    }
    .selected {
      color: var(--background);
      background-color: var(--text);
    }
  }
  .current{
    margin-right: 0.5rem;
  }
  .phase-selector {
    display: flex;
    margin: 4rem 0;
    .phase {
      color: var(--text);
      cursor: pointer;
      font-size: 1.4rem;
      font-weight: bold;
      margin-right: 3rem;
      position: relative;
      text-transform: uppercase;
      &.selected:after {
        background-color: var(--primary);
        bottom: -1rem;
        content: '';
        height: 3px;
        left: 0;
        position: absolute;
        width: 100%;
        @media only screen and (max-width: 768px) {
          bottom: 0.4rem;
        }
      }
    }
    @media only screen and (max-width: 768px) {
      overflow-x:scroll;
      min-height: 4rem;
    }
  }
  .jump-button {
    margin-left: 5rem;
    background-color: var(--primary);
    color: white !important;
    font-size: 1.2rem;
    font-weight: 400;
    margin: 2rem 0rem 2rem 1rem;
    max-width: fit-content;
    padding: 0.5rem 1.8rem;
    -webkit-text-decoration: none;
    text-decoration: none;
    cursor: pointer;
  }
  h1 {
    display: inline-block;
    font-size: 2.4rem;
    text-transform: uppercase;
  }
  .component-wrapper {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
  }
  .c-ScheduleDateBlock {
    max-width: 100rem;
  }
  .c-StandingsCard {
    margin-top: 3.5rem;
    max-width: 41.4rem;
  }
`;

export default PageWrapper;