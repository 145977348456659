import React, { Component } from 'react';
// import { Link } from 'gatsby';
import withContext from 'store/withContext';
import ScheduleDateBlock from 'components/Schedule/ScheduleDateBlock';
import {StandingsCard} from 'components/Global/StandingsCard';
import TeamSelector from 'components/Global/TeamSelector';

import PageWrapper from '../styles/schedule.css';
// import { isThisSecond } from 'date-fns';

class Schedule extends Component {

  constructor(props) {
    super(props);

    this.state = {
      phase: process.env.GATSBY_LEAGUE_PHASE,
      current: true,
      activeTeam: null,
      activeSchedule: null,
      phase_id:null
    }
  }

  filterPhase = (date) => {
    //Additional filter rule for Season 8 Phase 1 Group stage
     /*if (this.state.phase!='Phase 1'&& this.state.phase!='Phase 1 Playoffs' && this.state.phase!='Phase 2'&& this.state.phase != 'Phase 2 Playoffs' && this.state.phase != 'Phase 3' && this.state.phase != 'Phase 3 - Group A' && this.state.phase != 'Phase 3 - Group B' && this.state.phase != 'Phase 3 Playoffs' && this.state.phase != 'SWC Placements - Group A' && this.state.phase != 'SWC Placements - Group B' && this.state.phase != 'SMITE World Championship' && (date.phase === 'Phase 1 - Group A' ||date.phase === 'Phase 1 - Group B' )) {
      return true;
     }
     //Additional filter rule for Season 8 Phase 3 Group stage
     if (this.state.phase!='Phase 1'&& this.state.phase!='Phase 1 Playoffs' && this.state.phase!='Phase 2'&& this.state.phase != 'Phase 2 Playoffs' && this.state.phase != 'Phase 3' && this.state.phase != 'Phase 1 - Group A' && this.state.phase != 'Phase 1 - Group B' && this.state.phase != 'Phase 3 Playoffs' && this.state.phase != 'SWC Placements - Group A' && this.state.phase != 'SWC Placements - Group B' && this.state.phase != 'SMITE World Championship' && (date.phase === 'Phase 3 - Group A' || date.phase === 'Phase 3 - Group B' )) {
      return true;
    }
    //Additional filter rule for SWC Placements Group A
    if (this.state.phase=='SWC Placements - Group A' && (date.phase === 'SWC Placements - Group A')) {
      return true;
    }
    //Additional filter rule for SWC Placements Group B
    if (this.state.phase=='SWC Placements - Group B' && (date.date != 1639584000 && date.phase === 'SWC Placements - Group A')) {
      
      return true;
    }*/
    
    //Standard filter rule
    if (date.phase === this.state.phase) {
      return true;
    }
    
  }
  filterPhaseDate = (phase) => {
    //Additional filter rule for Season 8 Phase 1 Group stage
    
   
     if (this.state.phase!='Phase 1' && phase.title === 'Phase 1 - Group A') {
       return true;
     }
     if (this.state.phase=='SWC Placements - Group A' && (phase.title === 'SWC Placements - Group A')) {
      return true;
    }
    if (this.state.phase=='SWC Placements - Group B' && (phase.title === 'SWC Placements - Group B')) {
      return true;
    }
    //Standard filter rule
    if (phase.title === this.state.phase) {
      return true;
    }
  }
  componentDidMount() {
    if (this.props.context.schedule) {
      this.setState({activeSchedule: this.props.context.schedule});
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.context.schedule !== this.props.context.schedule) {
      this.setState({activeSchedule: this.props.context.schedule});
    }
  }

  getTeamSchedule = (e) => {
    fetch(`${this.props.context.baseUrl}/schedule/${this.props.context.eventId}${e.target.value !== 'all' ? `/${e.target.value}` : ''}`)
    .then(response => response.json())
    .then(response => {
      let activeTeam = (e.target.value === 'all') ? 'null' : this.props.context.teams.find(team => team.team_id === parseInt(response.requested_team));
      this.setState(() => ({
        activeSchedule:response,
        activeTeam: activeTeam.name
      }));
    });
  }
  updateStandings = (phase_id) => {
    fetch(`${this.props.context.baseUrl}/standings/${this.props.context.eventId}${phase_id ? '/' + phase_id : ''}`)
    .then(response => response.json())
    .then(response => {
      this.setState({standings: response});
    });
  }
  scrollToCurrent = () => {
    let targetTime= Math.floor(Date.now() / 1000);
    let target=null;
    this.setState(
      {phase: this.state.activeSchedule.phases[this.state.activeSchedule.phases.length-1].title},
      ()=>{
        this.state.activeSchedule.schedule.map((item)=>{
        if(!target&& item.date>targetTime){
          target= document.querySelector(`#match_${item.date}`)
        }
      })
      if(!target){
        target=document.querySelector(`#match_${this.state.activeSchedule.schedule[this.state.activeSchedule.schedule.length-1].date}`)
      }
        target.scrollIntoView({behavior:'smooth'})
      }
      )
    
  }

  render() {
    return (
      <PageWrapper>
        <div className="center-wrapper">
          <div className="page-controls">
            <h1>{process.env.GATSBY_LEAGUE_HEADER_LONG} Schedule</h1>
           
            <TeamSelector 
              activeTeam={this.state.activeTeam}
              teams={this.props.context.teams && this.props.context.teams}
              callback={this.getTeamSchedule}
              page={'Schedules'} 
              />
            {/* <div className="current-selector">
              <div className={`current ${this.state.current ? 'selected' : ''}`} onClick={() => this.setState({current: true})}>Current Schedule</div>
              <div className={`all ${!this.state.current ? 'selected' : ''}`} onClick={() => this.setState({current: false})}>All Dates</div>
            </div> */}
            <div onClick={this.scrollToCurrent.bind(this)} className="jump-button">Current Schedule</div>
            <div className="phase-selector">
              {(this.state.activeSchedule && this.state.activeSchedule.hasOwnProperty('phases')) && this.state.activeSchedule.phases.map((phase, i) => 
                <div className={`phase ${this.state.phase === phase.title ? 'selected' : ''}`} onClick={() => this.setState({phase: phase.title, phase_id:phase.id}, this.updateStandings(phase.id))} key={phase.title + i}>{phase.title}</div>
              )}
            </div>
          </div>
          <div className="component-wrapper">
            <ScheduleDateBlock
              schedule={this.state.activeSchedule !== null && this.state.activeSchedule.schedule.filter(this.filterPhase)}
              phase={this.state.phase}
              date_range={this.state.activeSchedule !== null && this.state.activeSchedule.phases !== null && `${new Date(this.state.activeSchedule.phases.filter(this.filterPhaseDate)[0].start_date*1000).toLocaleDateString(navigator.language,{ month: 'long', day: 'numeric', year:'numeric'})} - ${new Date(this.state.activeSchedule.phases.filter(this.filterPhaseDate)[0].end_date*1000).toLocaleDateString(navigator.language,{ month: 'long', day: 'numeric', year:'numeric'})}`}
              stream_url={this.state.activeSchedule !== null && this.state.activeSchedule.stream_url} 
            />
            {/*force refresh standings if we don't have a standings state already set*/}
            
            {!this.state.standings&& this.state.activeSchedule && this.state.activeSchedule.phases && this.updateStandings(this.state.activeSchedule.phases[this.state.activeSchedule.phases.length-1].id)}
            <StandingsCard
              header={this.state.phase}
              season={process.env.GATSBY_LEAGUE_HEADER}
              data={this.state.standings||this.props.context.standings}
            />
          </div>
        </div>
      </PageWrapper>
    );
  }
}

export default withContext(Schedule);
